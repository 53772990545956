@use 'sass:math';
@import '../../style/utils/variables';
@import '../../style/utils/mixins';
@import '../../style/utils/breakpoints';
@import '../../style/utils/helpers';
@import '../../style/base/fonts';
@import '../../style/base/layout';
@import '../../style/base/typography';
@import '../../style/layout/buttons';

.feed {
  padding: 50px 0 40px;
  width: 100%;
}

.feed__light__gray {
  background-color: #F8F8F8;
  ;
}

.feed__title {
  margin-bottom: 39px;
}

.feed__list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.feed__link__wrap {
  padding-top: 17px;
  text-align: center;
  width: 100%;
}
