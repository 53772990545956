@import '../../../style/utils/variables';
@import '../../../style/utils/breakpoints';
@import '../../../style/utils/reset';
@import '../../../style/utils/helpers';
@import '../../../style/base/fonts';
@import '../../../style/base/layout';
@import '../../../style/utils/mixins';
@import '../../../style/layout/icon-font';
@import '../../../style/layout/buttons';

.footer {
  background-color: $black;
  color: $white;
  font-family: $poppins;
  padding: 50px 0 30px;
  width: 100%;
 
  @include media-breakpoint-down(sm) {
    padding: 50px 0 20px;
  }
}

.footer__inner {
  position: relative;
  width: 100%;
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
  }
}

.footer__logo {
  max-width: 100%;
  display: inline-block;
  margin-bottom: 18px;
}

.footer__info {
  width: 33%;
  @include media-breakpoint-down(sm) {
    margin-bottom: 26px;
    width: 100%;
  }
}

.footer__contact {
  display: block;
  margin-bottom: 26px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 16px;
  }
}

.footer__contact__item {
  margin-bottom: 10px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 5px;
  }
}

.footer__contact__span {
  color: $white;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
}

.footer__copy {
  color: rgba($white, 0.7);
  a {
    color: inherit;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer__nav {
  display: flex;
  padding-bottom: 40px;
  width: 57%;
  @include media-breakpoint-only(md) {
    width: 60%;
  }
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    padding-bottom: 0;
    width: 100%;
  }
}

.footer__list {
  padding-right: 20px;
  width: 100%;
  @include media-breakpoint-down(sm) {
    margin-bottom: 15px;
    width: 50%;
  }
}

.footer__item {
  margin-bottom: 8px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 5px;
  }
}

.footer__title {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 16px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 10px;
  }
}

.footer__link {
  color: $white;
  &:hover {
    text-decoration: underline;
  }
}

.footer__to__top {
  bottom: 20px;
  color: $white;
  font-size: 13px;
  padding-top: 24px;
  position: absolute;
  right: 0;
  @include icon(arrow-long-top) {
    font-size: 16px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: 0;
  }
  @include media-breakpoint-down(sm) {
    bottom: auto;
    top: 0;
  }
}
