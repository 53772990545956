@import '../../style/base/layout';
@import '../../style/base/typography';
@import '../../style/utils/breakpoints';
@import '../../style/utils/mixins';
@import '../../style/utils/variables';
@import '../../style/utils/helpers';

.feed__card {
    background-color: $white;
    box-shadow: 0 0 10px rgba($black, 0.15);
    color: $black;
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;
    margin-right: 2.4%;
    width: 23.2%;
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:hover {
      .feed__image {
        transform: translate(-50%, -50%) scale(1.1);
      }
      .feed__heading__text {
        background-size: 100% 2px;
      }
      .feed__read__more {
        color: $pink;
      }
    }
    @include media-breakpoint-down(sm) {
      margin: 0 0 20px;
      width: 100%;
    }
  }
  
.feed__card__blog {
	width: 33.3%;
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.feed__image__holder {
	padding-bottom: 47%;
	overflow: hidden;
	position: relative;
	width: 100%;
	@include media-breakpoint-only(md) {
		padding-bottom: 60%;
	}
}

.feed__image {
	height: 100%;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: transform $transition;
	width: 100%;
}

.feed__text {
	padding: 15px 17px 17px 8px;
	width: 100%;
	@include media-breakpoint-only(md) {
		padding: 10px;
	}
	@include media-breakpoint-down(sm) {
		padding: 15px;
	}
}

.feed__heading {
	font-size: fluid(16px, 18px);
	font-weight: 400;
	line-height: 21px;
	margin-bottom: 8px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	@include media-breakpoint-only(md) {
		font-size: 15px;
		line-height: 20px;
	}
}

.feed__heading__text {
	background: linear-gradient($black 0, $black 100%);
	background-position: left bottom;
	background-repeat: no-repeat;
	background-size: 0 2px;
	transition: background $transition;
}

.feed__category {
	margin-bottom: 16px;
}

.feed__more {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
}

.feed__read__more {
	font-size: 12px;
	line-height: 21px;
	@include icon(arrow-long-right, after) {
		font-size: 13px;
		margin-left: 9px;
		position: relative;
		top: 3px;
	}
}

.feed__date {
	color: rgba($black, 0.35);
	font-size: 12px;
	line-height: 21px;
}
