@use 'sass:math';
@import '../../style/utils/variables';
@import '../../style/utils/mixins';
@import '../../style/utils/breakpoints';
@import '../../style/utils/helpers';
@import '../../style/base/fonts';
@import '../../style/base/layout';
@import '../../style/base/typography';
@import '../../style/layout/buttons';
@import '../../style/layout/forms';

.contact__details {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 33px 35px;
  width: 42.6%;
  @include media-breakpoint-only(md) {
    justify-content: flex-start;
    padding: 20px 0 33px 20px;
  }
  @include media-breakpoint-down(sm) {
    padding: 30px 0 0;
    width: 100%;
  }
}

.contact__info {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 36px;
  padding-left: 60px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  &::before {
    color: $pink;
    font-size: 28px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  @include media-breakpoint-only(md) {
    padding-left: 40px;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 24px;
  }
}

a.contact__info {
  @include animation {
    transition: color $transition;
  }
  @include hover {
    color: $pink;
  }
}

.contact__info__mail {
  @include icon(twitter);
}

.contact__info__phone {
  @include icon(phone);
}

.contact__info__pin {
  @include icon(pin);
}
