@import '../../style/base/layout';
@import '../../style/base/typography';
@import '../../style/utils/breakpoints';
@import '../../style/utils/mixins';
@import '../../style/utils/variables';
@import '../../style/utils/helpers';
@import '../../style/layout/buttons';

.basic__block__holder {
  width: 100%;
}

.basic__block__row {
  align-items: flex-start;
  background-color: $white;
  box-shadow: 1px 1px 4px rgba($black, 0.15);
  display: flex;
  margin-bottom: 24px;
  padding: 24px;
  width: 100%;
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}

.basic__block__text {
  width: 100%;
  @include media-breakpoint-down(sm) {
    order: 2;
  }
}

.basic__block__head {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 16px;
}

.basic__block__par {
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 24px;
}

.basic__block__image {
  flex-shrink: 0;
  margin-left: 20px;
  width: 22%;
  @include media-breakpoint-down(sm) {
    margin: 0 0 20px;
    width: 50%;
  }
}
