// ----------  layout
// ------------------------------------------------------------------------------
@import '../utils/variables';

body {
  background-color: #f8f8f8;
  color: $black;
  font-family: $font-default;
  font-size: 14px;
  font-weight: 400;
}

.scroll__disabled {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.wrap {
  margin: 0 auto;
  max-width: 1234px;
  padding: 0 20px;
  width: 100%;
}

[data-object-fit='cover'] {
  object-fit: cover;
}

[data-object-fit='contain'] {
  object-fit: contain;
}
