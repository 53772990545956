@import '../../../style/utils/variables';
@import '../../../style/utils/breakpoints';
@import '../../../style/utils/reset';
@import '../../../style/utils/helpers';
@import '../../../style/base/fonts';
@import '../../../style/base/layout';
@import '../../../style/utils/mixins';
@import '../../../style/layout/icon-font';
@import '../../../style/layout/buttons';

.header {
  border-bottom: 1px solid rgba($black, 0.08);
  position: relative;
  width: 100%;
  z-index: 10;

  @include media-breakpoint-only(md) {
    height: 82px;
  }
  @include media-breakpoint-down(sm) {
    height: 91px;
  }
}

.mobile__menu {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 999;
  }
}

.header__wrap {
  @include media-breakpoint-down(md) {
    display: inline-block;
  }
}

.header__top {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-down(md) {
    justify-content: flex-end;
    order: 2;
    display: none;
  }
}

.header__actions {
  align-items: center;
  display: flex;
  @include media-breakpoint-down(md) {
    border-right: 1px solid $black;
    margin-right: 20px;
    padding-right: 20px;
  }
}

.header__social {
  font-size: 0;
  padding-top: 7px;
  @include media-breakpoint-down(md) {
    border-bottom: 1px solid rgba($black, 0.08);
    display: flex;
    justify-content: space-evenly;
    left: 0;
    padding: 10px 20px 5px;
    width: 100%;
    margin-top: 50px;
  }
}

.header__social__item {
  display: inline-block;
  margin-left: 20px;
  @include media-breakpoint-only(md) {
    margin-left: 0;
  }
}

.header__social__link {
  color: $black;
  font-size: 20px;
  transition: color $transition;
  &:hover {
    color: $pink;
  }
}

.header__btn {
  background-color: $black;
  color: $white;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  margin-left: 35px;
  padding: 16px 33px 15px;
  transition: background-color $transition;
  &:hover {
    background-color: $pink;
  }
}

.header__btn__small {
  display: none;

  @include media-breakpoint-down(md) {
    display: flex;
    background-color: $black;
    color: $white;
    font-size: 12px;
    font-weight: 700;
    padding: 8px 25px;
    transition: background-color $transition;
  }
}

.header__container {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1234px;
  padding: 0 20px;
  @include media-breakpoint-down(md) {
    justify-content: space-between;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 5px;
    justify-content: space-between;
  }
}

.header__logo {
  width: 58px;
  height: 58px;
}

.header__logo__img {
  max-width: 100%;
  max-height: 100%;
}

.nav__bar {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.hamburger__logo {
  display: flex;
  flex-direction: row;

  @include media-breakpoint-only(md) {
    justify-content: space-between;
    margin-left: 10px;
  }
  @include media-breakpoint-down(sm) {
    justify-content: space-between;
    margin-left: 10px;
  }
}

.hamburger__btn {
  display: none;
  flex-direction: column;
  align-self: center;
  margin-right: 40px;

  @include media-breakpoint-only(md) {
    display: flex;
  }
  @include media-breakpoint-down(sm) {
    margin-right: 30px;
    display: flex;
  }
}

.hamburger__line {
  width: 25px;
  height: 2px;
  background-color: #333;
  margin: 2px 0;
}

.cross {
  position: relative;
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.cross::before,
.cross::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: black;
  align-self: center;
  display: flex;
  top: 50%;
}

.cross::before {
  transform: rotate(45deg);
}

.cross::after {
  transform: rotate(-45deg);
}

.mobile__emails {
  margin-left: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.divider {
  height: 1px;
  background-color: rgba($black, 0.08);
  width: 100%;
  margin-top: 30px;
}
