@import '../../style/utils/variables';
@import '../../style/utils/breakpoints';
@import '../../style/utils/reset';
@import '../../style/utils/helpers';
@import '../../style/base/fonts';
@import '../../style/base/layout';
@import '../../style/utils/mixins';
@import '../../style/layout/buttons';

.nav {
  display: block;
  @include media-breakpoint-down(md) {
    background-color: $white;
    bottom: 0;
    left: 0;
    overflow: auto;
    right: 0;
  }
}

.nav__list {
  display: flex;
  flex-direction: row;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.nav__open {
  @include media-breakpoint-down(md) {
    display: block;
  }
}
