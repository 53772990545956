@import '../../style/base/layout';
@import '../../style/base/typography';
@import '../../style/utils/breakpoints';
@import '../../style/utils/mixins';
@import '../../style/utils/variables';
@import '../../style/utils/helpers';

// ----------  basic-block
// ------------------------------------------------------------------------------
.basic__block {
  padding: 48px 0;
}

.basic__block__heading {
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 32px;
}

